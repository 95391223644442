<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    en: {
    },
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
    Calendar
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "name", label: "Nome", sortable: true },
        { key: "order", label: "Pedido", sortable: true },
        { key: "date", label: "Data", sortable: true },
        { key: "value", label: "Valor", sortable: true },
        { key: "level", label: "Nível", sortable: true },
        { key: "bonus", label: "Bônus", sortable: true },
      ],
      items: null,

      sortBy: "date",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      totalBonus: 0,
    };
  },
  methods: {
    getPeriod() {
      if (this.periodMonth) {
        const date = [
          new Date(this.periodMonth).getFullYear(),
          new Date(this.periodMonth).getMonth() + 1
        ];

        return date.join('-');
      } else {
        const date = [
          new Date().getFullYear(),
          new Date().getMonth() + 1
        ];

        return date.join('-');
      }
    },
    getList() {
      this.items = null;

      this.loading = true;
      this.errored = false;
      this.empty = false;

      api
        .get("apvs/diretor?date=" + this.getPeriod())
        .then((response) => {
          if (response.data.status == 'success') {
            this.items = response.data.list;
            this.total = response.data.list.length;
            this.totalBonus = response.data.total;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
          if (this.items == "" || this.items == null) {
            this.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    'periodMonth': function () {
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">APVS</li>
      <li class="breadcrumb-item d-none d-sm-block">Diretor</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Diretor</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.totalBonus | currency }}</h6>
        <h6 class="m-0 text-secondary">Total de bônus</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Filtrar período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-group>
          <Calendar v-model="periodMonth" view="month" dateFormat="yy-mm-dd" inline :locale="br" style="width: 250px;">
          </Calendar>
        </b-dropdown-group>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div class="card">
      <div class="card-body min-vh-50">
        <div class="error text-center min-vh-50" v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="empty text-center min-vh-50">
          <strong>Nenhuma movimentação no período.</strong>
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(value)="row">
              {{ row.item.value | currency }}
            </template>
            <template #cell(bonus)="row">
              {{ row.item.bonus | currency }}
            </template>
          </b-table>
        </div>
        <b-pagination class="m-0 mt-3" v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>
      </div>
    </div>
  </Layout>
</template>
